import type { QcStationState } from '../screens/manufacturer/components/QcStation/state/QcStation.state';
import type { ManufacturerState } from '../screens/manufacturer/state/manufacturer.reducer';
import type { SelectStaffState } from '../screens/select-staff/state/select-staff.reducer';
import type { OrdersState } from './orders/orders.reducer';
import type { PracticeUIState } from './ui';
import type { StateWithAsync } from '@orthly/redux-async-actions';
import type { TypedUseSelectorHook } from 'react-redux';
import { useSelector } from 'react-redux';
import type { RouterState } from 'redux-first-history';

export interface PartnerAppState extends StateWithAsync {
    manufacturer: ManufacturerState;
    orders: OrdersState;
    router: RouterState;
    ui: PracticeUIState;
    qcStation: QcStationState;
    staffMember: SelectStaffState;
}

export const usePracticeAppSelector: TypedUseSelectorHook<PartnerAppState> = useSelector;
