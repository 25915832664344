import type { ModelAppearance, PayloadModelAppearance } from '../ModelAppearance/ModelAppearanceTypes';
import { CURRENT_TOOTH_COLOR_HEX_STRING, PAST_TOOTH_COLOR_HEX_STRING } from '../ModelViewer/defaultModelColors';
import { TransparencySlider } from './TransparencySlider';
import type { DandyAnalyticsEventSchemaType } from '@orthly/analytics/dist/browser';
import { BrowserAnalyticsClientFactory, OrderAnalyticsContext } from '@orthly/analytics/dist/browser';
import {
    Text,
    FlossPalette,
    Button,
    stylesFactory,
    Card,
    Grid,
    IconButton,
    CloseIcon,
    ToggleOffIcon,
    ToggleOnIcon,
} from '@orthly/ui-primitives';
import cx from 'classnames';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(() => ({
    menuCard: {
        padding: 4,
        borderRadius: 8,
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        width: 360,
    },
    gridRow: {
        padding: `8px 4px`,
    },
    gridDivider: {
        borderTop: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    },
    colorLegendItem: {
        width: 'auto',
        marginRight: 8,
    },
    colorLegendItemOrb: {
        height: 14,
        width: 14,
        borderRadius: 14,
    },
    pastMarginsWrapper: {
        width: 'auto',
        margin: 'auto 0',
        cursor: 'pointer',
    },
    pastMarginsLabel: {
        marginRight: 8,
    },
    colorLegendItems: {
        paddingLeft: 4,
    },
    detailValue: {
        color: FlossPalette.BLACK,
    },
    colorRestorativeWrapper: {
        cursor: 'pointer',
    },
}));

type DisplayLocationT =
    DandyAnalyticsEventSchemaType['All - Portal - Design Comparison - Tool Used']['displayLocation'];

function useTrackToolUsed(displayLocation: DisplayLocationT) {
    const analyticsContext = React.useContext(OrderAnalyticsContext);
    const orderId = analyticsContext?.orderId;

    const trackToolUsed = React.useCallback(
        (
            action: DandyAnalyticsEventSchemaType['All - Portal - Design Comparison - Tool Used']['action'],
            isActive?: boolean,
        ) => {
            if (!orderId) {
                return;
            }

            BrowserAnalyticsClientFactory.Instance?.track('All - Portal - Design Comparison - Tool Used', {
                $groups: { order: orderId },
                action,
                isActive,
                displayLocation,
            });
        },
        [orderId, displayLocation],
    );

    const trackResetTransparencyUsed = React.useCallback(() => trackToolUsed('reset_transparency'), [trackToolUsed]);

    return { trackToolUsed, trackResetTransparencyUsed };
}

interface ColorLegendItemProps {
    color: string;
    label: string;
}

const ColorLegendItem: React.FC<ColorLegendItemProps> = ({ color, label }) => {
    const classes = useStyles();

    return (
        <Grid
            item
            container
            direction={'row'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            spacing={1}
            className={classes.colorLegendItem}
        >
            <Grid item className={classes.colorLegendItemOrb} style={{ backgroundColor: color }} />
            <Grid item>
                <Text variant={'body2'} color={'LIGHT_GRAY'}>
                    {label}
                </Text>
            </Grid>
        </Grid>
    );
};

interface DesignComparisonMenuProps {
    appearance: ModelAppearance;
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>;
    maxMarginDistanceMm?: number;
    displayLocation: DisplayLocationT;
    setDesiredComparisonDesignFiles: React.Dispatch<React.SetStateAction<string[]>>;
}

type CurrentView = 'both' | 'current' | 'past' | 'none';
const CurrentViewNextViewMap: Record<CurrentView, CurrentView> = {
    both: 'current',
    current: 'past',
    past: 'both',
    none: 'current',
};

export const DesignComparisonMenu: React.FC<DesignComparisonMenuProps> = ({
    appearance,
    setAppearance,
    maxMarginDistanceMm,
    displayLocation,
    setDesiredComparisonDesignFiles,
}) => {
    const classes = useStyles();

    const { trackToolUsed, trackResetTransparencyUsed } = useTrackToolUsed(displayLocation);

    const toggleColorPastRestoratives = React.useCallback(() => {
        trackToolUsed('show_color', !appearance.colorPastRestoratives);
        setAppearance(current => ({ ...current, colorPastRestoratives: !current.colorPastRestoratives }));
    }, [appearance.colorPastRestoratives, setAppearance, trackToolUsed]);

    const toggleVisibility = React.useCallback(
        (variant: 'current' | 'past') => {
            trackToolUsed('toggle_visibility');

            setAppearance(current => {
                const getVisibilitySetter = (visible: boolean) => {
                    return (pma: PayloadModelAppearance) => ({
                        ...pma,
                        appearance: { ...pma.appearance, visible },
                    });
                };

                const restoratives = current.restoratives.map(getVisibilitySetter(variant === 'current'));
                const pastRestoratives = current.pastRestoratives.map(getVisibilitySetter(variant === 'past'));

                return {
                    ...current,
                    restoratives,
                    pastRestoratives,
                };
            });
        },
        [setAppearance, trackToolUsed],
    );

    const showBothVersions = React.useCallback(() => {
        trackToolUsed('show_both');

        setAppearance(current => {
            const setVisible = (pma: PayloadModelAppearance) => ({
                ...pma,
                appearance: { ...pma.appearance, visible: true },
            });

            const restoratives = current.restoratives.map(setVisible);
            const pastRestoratives = current.pastRestoratives.map(setVisible);

            return {
                ...current,
                restoratives,
                pastRestoratives,
            };
        });
    }, [setAppearance, trackToolUsed]);

    const toggleShowPastMarginLines = React.useCallback(() => {
        trackToolUsed('show_margins', !appearance.showPreviousMarginLines);
        setAppearance(current => ({ ...current, showPreviousMarginLines: !current.showPreviousMarginLines }));
    }, [appearance.showPreviousMarginLines, setAppearance, trackToolUsed]);

    const anyCurrentVisible = appearance.restoratives.some(pma => pma.appearance.visible);
    const anyPastVisible = appearance.pastRestoratives.some(pma => pma.appearance.visible);

    /* eslint-disable no-nested-ternary */
    const currentView: CurrentView =
        anyCurrentVisible && anyPastVisible ? 'both' : anyCurrentVisible ? 'current' : anyPastVisible ? 'past' : 'none';

    const handleChangeView = () => {
        const nextView = CurrentViewNextViewMap[currentView];

        if (nextView === 'both') {
            showBothVersions();
        } else if (nextView === 'past' || nextView === 'current') {
            toggleVisibility(nextView);
        }
    };

    return (
        <Card raised={true} className={classes.menuCard}>
            <Grid container direction={'column'}>
                <Grid container item direction={'row'} className={classes.gridRow}>
                    <Grid item>
                        <IconButton onClick={() => setDesiredComparisonDesignFiles([])}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs />
                    <Grid
                        container
                        direction={'row'}
                        item
                        wrap={'nowrap'}
                        className={classes.pastMarginsWrapper}
                        onClick={toggleShowPastMarginLines}
                    >
                        <Grid item className={classes.pastMarginsLabel}>
                            <Text variant={'body2'} medium color={'GRAY'}>
                                Past margins
                            </Text>
                        </Grid>
                        <Grid item>{appearance.showPreviousMarginLines ? <ToggleOnIcon /> : <ToggleOffIcon />}</Grid>
                    </Grid>
                </Grid>
                {maxMarginDistanceMm !== undefined && appearance.showPreviousMarginLines ? (
                    <Grid item className={cx(classes.gridRow, classes.gridDivider)}>
                        <Text variant={'body2'} color={'GRAY'} medium>
                            Greatest distance:{' '}
                            <span className={classes.detailValue}>{maxMarginDistanceMm.toFixed(2)} mm</span>
                        </Text>
                    </Grid>
                ) : null}
                <Grid
                    container
                    item
                    direction={'row'}
                    className={cx(classes.colorRestorativeWrapper, classes.gridRow, classes.gridDivider)}
                    onClick={toggleColorPastRestoratives}
                >
                    <Grid item>
                        <Text variant={'body2'} medium color={'GRAY'}>
                            Color restoratives
                        </Text>
                    </Grid>
                    <Grid item xs />
                    <Grid item>{appearance.colorPastRestoratives ? <ToggleOnIcon /> : <ToggleOffIcon />}</Grid>
                </Grid>
                <Grid
                    className={cx(classes.gridRow, classes.gridDivider)}
                    container
                    item
                    direction={'row'}
                    wrap={'nowrap'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Grid item>
                        <Text variant={'body2'} color={'GRAY'} medium>
                            Currently viewing: <span className={classes.detailValue}>{_.upperFirst(currentView)}</span>
                        </Text>
                        <Grid
                            container
                            direction={'row'}
                            justifyContent={'flex-start'}
                            className={classes.colorLegendItems}
                        >
                            <ColorLegendItem label={'Current'} color={CURRENT_TOOTH_COLOR_HEX_STRING} />
                            <ColorLegendItem label={'Past'} color={PAST_TOOTH_COLOR_HEX_STRING} />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button variant={'secondary'} onClick={handleChangeView}>
                            Show {_.upperFirst(CurrentViewNextViewMap[currentView])}
                        </Button>
                    </Grid>
                </Grid>
                {!!appearance.activeHeatMap && (
                    <Grid className={cx(classes.gridRow, classes.gridDivider)} item>
                        <TransparencySlider
                            setAppearance={setAppearance}
                            displayLocation={displayLocation}
                            trackResetUsed={trackResetTransparencyUsed}
                        />
                    </Grid>
                )}
            </Grid>
        </Card>
    );
};
