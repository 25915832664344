import type { CompletedStage, StageSelections } from '../internal-remake.types';
import React from 'react';

export function useStageSelections(stages: CompletedStage[]) {
    const [selections, setSelections] = React.useState<StageSelections>(() =>
        stages.reduce<StageSelections>((acc, stage) => {
            acc[stage.id] = {
                deptId: stage.deptId,
                stageId: stage.stageId,
                selected: false,
            };
            return acc;
        }, {}),
    );

    // Reset selections when stages change
    React.useEffect(() => {
        const newSelections = stages.reduce<StageSelections>((acc, stage) => {
            acc[stage.id] = {
                deptId: stage.deptId,
                stageId: stage.stageId,
                selected: false,
            };
            return acc;
        }, {});
        setSelections(newSelections);
    }, [stages]);

    const toggleStageSelection = (stageId: string): void => {
        setSelections(prev => {
            const currentStage = prev[stageId];
            if (!currentStage) {
                console.warn(`Attempted to toggle non-existent stage: ${stageId}`);
                return prev;
            }
            return {
                ...prev,
                [stageId]: {
                    ...currentStage,
                    selected: !currentStage.selected,
                },
            };
        });
    };

    const getSelectedStages = () =>
        Object.values(selections)
            .filter(s => s.selected)
            .map(s => ({ deptId: s.deptId, stageId: s.stageId }));

    return {
        selections,
        toggleStageSelection,
        getSelectedStages,
    };
}
