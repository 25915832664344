//TODO EPDSCM-2076 move this component to a better shared library
import { orderIdFromQRCodeUrl } from '../../../utils/utils';
import { QRCodeOrderSearch } from '../../manufacturer/components/QcQrInput/QRCodeOrderSearch';
import { Text, Grid, styled, Button } from '@orthly/ui-primitives';
import label from '@orthly/ui/assets/images/pan-code-label.png';
import React from 'react';

const StyledFormGroup = styled(Grid)({
    padding: '20px',
});

const LabelImage = styled('img')({
    paddingBottom: '50px',
    width: '500px',
});

export const BatchShipmentsOrderSearchV2: React.VFC = () => {
    const [scannedOrderIds, setScannedOrderIds] = React.useState<string[]>([]);

    const findOrderAndAddToOpenList = (scannedUrl: string) => {
        const orderId = orderIdFromQRCodeUrl(scannedUrl);
        if (!orderId) {
            console.log(`${scannedUrl} not valid`);
            return;
        }

        //check for dupes, invalid?

        setScannedOrderIds([orderId, ...scannedOrderIds]);
    };

    return (
        <Grid>
            {scannedOrderIds.length > 0 && (
                <div>
                    <h1>SCANNED ORDERS</h1>
                    {scannedOrderIds.map(id => (
                        <h2 key={id}>{id}</h2>
                    ))}
                </div>
            )}

            <StyledFormGroup xs={12}>
                <Text color={'ATTENTION'}>Place your cursor in the input box below and scan the QR code</Text>
                <LabelImage src={label} alt={'Example Label'} />
                <QRCodeOrderSearch label={'Scan QR code'} setActiveSearch={findOrderAndAddToOpenList} />
            </StyledFormGroup>

            <Button
                style={{ maxHeight: 32 }}
                variant={'primary'}
                disabled={scannedOrderIds.length < 1}
                onClick={() => {
                    const message = `You are about to submit these ${scannedOrderIds.length} orders as a batch. Continue?`;
                    if (window.confirm(message)) {
                        console.log(`would have sent ${scannedOrderIds}`);
                    }
                }}
            >
                Mark Batch as Packed
            </Button>
        </Grid>
    );
};
