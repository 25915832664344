import { styled, Text } from '@orthly/ui-primitives';

export const StyledForm = styled('div')({
    padding: '5px',
    flexDirection: 'column',
    width: '100%',
});

export const StyledFormGroup = styled('div')({
    padding: '5px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
});

export const FormLabelText = styled(Text)({
    width: '200px',
    flexShrink: 0,
});

export const SingleStageItem = styled('div')({
    padding: '0px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
});

export const ScrollableStageList = styled('div')({
    width: '100%',
    maxHeight: '400px',
    overflowY: 'auto',
    border: '1px solid #e2e8f0',
    borderRadius: '4px',
    padding: '8px',
});
