import { useQuery } from '@apollo/client';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { MUITable, type MUITableColumn } from '@orthly/mui-table';
import React from 'react';

const ManifestBatchData_Fragment = graphql(`
    fragment ManifestBatchData on BatchShipManifestRowDTO {
        practiceName
        orderIds
        shipSpeed
        shelfLocation
    }
`);

const ManifestTableQuery = graphql(`
    query GetBatchShipManifest($manufacturerId: String!) {
        getBatchShipManifest(shippingManufacturerId: $manufacturerId) {
            batches {
                ...ManifestBatchData
            }
        }
    }
`);

export interface ManifestBatch {
    id: string; //id for react
    practiceName: string;
    orderIds: string[];
    shipSpeed: string;
    shelfLocation: string;
}

interface ManifestTableProps {
    id: string; //for react
    manufacturerId: string;
}

const ManifestRootColumns: MUITableColumn<ManifestBatch>[] = [
    { name: 'Practice Name', render: 'practiceName', filter: false },
    { name: 'Order Ids ', render: 'orderIds', filter: false },
    { name: 'Ship Speed', render: 'shipSpeed', filterOptions: { type: 'dropdown' } },
    { name: 'Shelf Location', render: 'shelfLocation', filterOptions: { type: 'multiselect' } },
];

export function useManifestTableQuery(manufacturerId: string): ManifestBatch[] {
    const { data } = useQuery(ManifestTableQuery, {
        variables: { manufacturerId: manufacturerId },
    });
    const batches = getFragmentData(ManifestBatchData_Fragment, data?.getBatchShipManifest.batches) ?? null;

    return (
        batches?.map((batch, i): ManifestBatch => {
            return {
                id: `batch ${i}`,
                practiceName: batch.practiceName,
                orderIds: batch.orderIds.map(id => {
                    return `${id}\n`;
                }),
                shipSpeed: batch.shipSpeed,
                shelfLocation: batch.shelfLocation,
            };
        }) ?? []
    );
}

export const Manifest: React.VFC<ManifestTableProps> = ({ manufacturerId }) => {
    const batches = useManifestTableQuery(manufacturerId);

    return (
        <MUITable<ManifestBatch>
            title={'Batch Shipping Manifest'}
            columns={ManifestRootColumns}
            data={batches}
            displayOptions={{
                filter: true,
                sort: true,
                fixedHeader: true,
                responsive: 'stacked',
                fixedSearch: true,
            }}
        />
    );
};
