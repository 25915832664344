import { InternalRemakeForm } from './internal-remake/components/InternalRemakeForm';
import type { InternalRemakeFormData } from './internal-remake/internal-remake.types';
import { useQuery, useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useRootActionCommand, RootActionDialog } from '@orthly/ui';
import { Button } from '@orthly/ui-primitives';
import React from 'react';

const LabGetCompletedStagesForLabOrder = graphql(`
    query LabGetCompletedStagesForLabOrder_Query($labOrderId: String!) {
        stages: getCompletedStagesForOrder(labOrderId: $labOrderId) {
            deptId
            stageId
        }
    }
`);

const LabRecordInternalRemake_Mutation = graphql(`
    mutation RecordInternalRemake(
        $causedByStage: ManufacturingStageInputDTO!
        $labOrderId: String!
        $requiresRestartFromStage: ManufacturingStageInputDTO!
        $stagesNeedingRedo: [ManufacturingStageInputDTO!]!
        $reason: String
        $newLmsOrderId: String
        $timestampOverride: DateTime
    ) {
        recordInternalRemake(
            causedByStage: $causedByStage
            labOrderId: $labOrderId
            requiresRestartFromStage: $requiresRestartFromStage
            stagesNeedingRedo: $stagesNeedingRedo
            reason: $reason
            newLmsOrderId: $newLmsOrderId
            timestampOverride: $timestampOverride
        )
    }
`);

interface RecordInternalRemakeModalProps {
    labOrderId: string;
}

export const RecordInternalRemakeModal: React.VFC<RecordInternalRemakeModalProps> = ({ labOrderId }) => {
    const [open, setOpen] = React.useState(false);

    // Fetch completed stages data
    const { data, loading: loadingStages } = useQuery(LabGetCompletedStagesForLabOrder, {
        variables: { labOrderId },
    });

    // Transform the raw stages data into the format we need
    const completedStages = React.useMemo(
        () =>
            data?.stages.map(stage => ({
                ...stage,
                id: `${stage.deptId} :: ${stage.stageId}`,
                label: `${stage.deptId} :: ${stage.stageId}`,
            })) ?? [],
        [data?.stages],
    );

    // Set up mutation handling
    const recordInternalRemakeMutation = useMutation(LabRecordInternalRemake_Mutation);
    const { submit: submitMutation, submitting } = useRootActionCommand(recordInternalRemakeMutation, {
        onSuccess: () => {
            setOpen(false);
        },
        successMessage: 'Internal Remake Recorded!',
    });

    const handleSubmit = (formData: InternalRemakeFormData) => {
        const { selectedStages, newLabtracId, reason, timestamp } = formData;

        // Use first selected stage as both cause and restart point
        const firstStage = selectedStages[0] || { deptId: '', stageId: '' };

        const mutationVariables = {
            causedByStage: firstStage,
            labOrderId,
            requiresRestartFromStage: firstStage,
            stagesNeedingRedo: selectedStages,
            reason: reason || 'No reason provided',
            newLmsOrderId: newLabtracId,
            timestampOverride: timestamp?.toISOString() ?? null,
        };

        return submitMutation(mutationVariables);
    };

    return (
        <RootActionDialog
            title={'Record IR'}
            subtitle={'Select all stages that need to be redone (None for Stage 2)'}
            loading={loadingStages}
            open={open}
            setOpen={setOpen}
            style={{ padding: 0 }}
            content={
                <InternalRemakeForm
                    completedStages={completedStages}
                    onSubmit={handleSubmit}
                    isSubmitting={submitting}
                />
            }
            CustomButton={buttonProps => (
                <Button {...buttonProps} fullWidth={false} variant={'text'} startIcon={'RefreshIcon'}>
                    Record IR
                </Button>
            )}
        />
    );
};
