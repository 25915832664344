import type { StageSelections } from './internal-remake.types';

export function validateInternalRemake(selections: StageSelections, newLabtracOrderId?: string): string | null {
    const hasSelectedStages = Object.values(selections).some(v => v.selected);

    if (hasSelectedStages && newLabtracOrderId) {
        return 'New Labtrac ID is only required for Stage 2 IRs';
    }

    if (!hasSelectedStages && !newLabtracOrderId) {
        return 'Please select at least one stage to redo or provide a new Labtrac ID for Stage 2 IRs';
    }

    return null;
}

export function getSanitizedLabtracId(newLabtracOrderId?: string) {
    if (!newLabtracOrderId) {
        return undefined;
    }
    const trimmedLabtracId = newLabtracOrderId.trim();
    const withoutPrefix = trimmedLabtracId.replace(/^CN[-\s]?/, '');
    return withoutPrefix;
}
