import { useManufacturerIdFromSession } from '../../utils/authorization';
import { useEnableBatchShippingManifest } from './batch-shipping.utils';
import { BatchShipmentsOrderSearch } from './components/BatchShipmentsOrderSearch.graphql';
import { BatchShipmentsOrderSearchV2 } from './components/BatchShipmentsOrderSearchV2.graphql';
import { Manifest } from './components/Manifest.graphql';
import { ShippingPrepPage } from './components/ShippingPrepPage.graphql';
import { FlossPalette, stylesFactory, Grid, Text, Tab, Tabs } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    pageHeader: {
        borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        height: '64px',
        lineHeight: '64px',
        fontSize: '40px',
        verticalAlign: 'middle',
        paddingLeft: '20px',
        paddingTop: '12px',
    },
}));

/**
 * Root component for the batch shipments pilot at Lehi. It allows fulfillment staff to search for orders and batch them
 * together into the same package / shipping label with other orders that shipped to the practice and address today.
 */
export const BatchShipmentsRoot: React.VFC = () => {
    const classes = useStyles();
    const enableBatchShippingManifest = useEnableBatchShippingManifest();
    const [tab, setTab] = React.useState<'prep' | 'manifest'>('prep');
    const mfgId = useManufacturerIdFromSession();

    if (enableBatchShippingManifest) {
        return (
            <Grid container direction={'column'}>
                <Grid container className={classes.pageHeader}>
                    <Text variant={'h4'}>Batch shipments</Text>
                </Grid>
                <Tabs value={tab} onChange={(_, value) => setTab(value)}>
                    <Tab label={'Prep'} value={'prep'} />
                    <Tab label={'Manifest'} value={'manifest'} />
                </Tabs>
                <Grid>
                    {tab === 'prep' && <ShippingPrepPage />}
                    {tab === 'manifest' && mfgId && (
                        <Grid>
                            <BatchShipmentsOrderSearchV2 />
                            <Manifest id={'manifest-table'} manufacturerId={mfgId} />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        );
    } else {
        return (
            <Grid container direction={'column'}>
                <Grid container className={classes.pageHeader}>
                    <Text variant={'h4'}>Batch shipments</Text>
                </Grid>
                <BatchShipmentsOrderSearch />
            </Grid>
        );
    }
};
