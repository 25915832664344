import { BatchShipFoundOrderSection } from './BatchShipFoundOrderSection.graphql';
import { BatchShipmentsSearchBox } from './BatchShipSearchBox';
import { useMutation } from '@apollo/client';
import { graphql, getFragmentData } from '@orthly/graphql-inline-react';
import { LoadBlocker, apolloErrorMessage } from '@orthly/ui';
import { Collapse, Grid } from '@orthly/ui-primitives';
import { useSnackbar } from 'notistack';
import React from 'react';

const BatchShipSearchResultFragment_Doc = graphql(`
    fragment BatchShipCandidateSearchResult on BatchShipCandidatesSearchResult {
        expected_to_ship_today_count
        expected_to_ship_tomorrow_count
        holdback_until_tomorrow
        search_result {
            ...BatchShipCandidateOrder
        }
        shipped_today {
            ...BatchShipCandidateOrder
        }
        batch_shelf_label
    }
`);

const RecordScanAndGetCandidates_Doc = graphql(`
    mutation RecordScanAndGetBatchShipCandidates($search: String!) {
        recordShippingDeptScanAndFetchBatchCandidates(search: $search) {
            ...BatchShipCandidateSearchResult
        }
    }
`);

export const BatchShipmentsOrderSearch: React.VFC = () => {
    const [orderSearch, _setOrderSearch] = React.useState<string>();
    const { enqueueSnackbar } = useSnackbar();
    const [triggerMtn, { data: mtnResult, loading }] = useMutation(RecordScanAndGetCandidates_Doc, {
        variables: { search: orderSearch ?? '' },
        fetchPolicy: 'no-cache',
        onError: error => {
            enqueueSnackbar(apolloErrorMessage(error), { variant: 'error' });
            _setOrderSearch(undefined);
        },
    });
    const setOrderSearch = React.useCallback(
        (search: string | undefined) => {
            _setOrderSearch(search);
            if (search && search !== orderSearch) {
                void triggerMtn({ variables: { search } });
            }
        },
        [orderSearch, triggerMtn],
    );
    const refetch = React.useCallback(async (): Promise<void> => {
        await triggerMtn();
    }, [triggerMtn]);
    const fragmentData = getFragmentData(
        BatchShipSearchResultFragment_Doc,
        mtnResult?.recordShippingDeptScanAndFetchBatchCandidates,
    );

    return (
        <LoadBlocker blocking={loading}>
            <Grid container style={{ padding: '20px 20px 50px' }}>
                <BatchShipmentsSearchBox orderSearch={orderSearch} setOrderSearch={setOrderSearch} />
                <Collapse in={!!fragmentData} style={{ width: '100%' }}>
                    {fragmentData && (
                        <BatchShipFoundOrderSection
                            query={fragmentData}
                            refetchQuery={refetch}
                            searchLoading={loading}
                        />
                    )}
                </Collapse>
            </Grid>
        </LoadBlocker>
    );
};
