import { AnalyticsClient } from '../../../../../../analytics/analyticsClient';
import { LabInboxBulkActionIconButton } from '../../../../components/bulk-actions/LabInboxBulkActionIconButton';
import { usePrintDialog } from '../../utils/usePrintDialog';
import type { LabPortalOrderActionProps } from './LabPortalOrderActions.types';
import { Format } from '@orthly/runtime-utils';
import { Icon } from '@orthly/ui-primitives';
import { usePrintableState, PanLabelSeries } from '@orthly/veneer';
import React from 'react';

const recordBulkPanLabelPrint = (orderIds: string[]) => {
    AnalyticsClient.track('Lab - Portal - Pan Label Opened', {
        displayLocation: 'order_list_view', // just differentiate between bulk and single pan label print, exact URL shows the exact lab portal page/list view
        orderIds,
    });
};

export const LabPortalPrintPanLabelAction: React.FC<LabPortalOrderActionProps> = props => {
    const { selectedOrders, disabled } = props;
    const { printableIsOpen, openPrintable, closePrintable } = usePrintableState();

    const itemsLabel = props.onSingleRow
        ? 'pan labels for order'
        : `pan labels for ${Format.pluralize('order', selectedOrders.length)}`;
    const title = selectedOrders.length > 0 ? `Print ${itemsLabel}` : '';

    const renderPanLabels = React.useCallback(async () => {
        if (selectedOrders.length > 0) {
            const orderIds = selectedOrders.map(order => order.id);
            openPrintable();
            recordBulkPanLabelPrint(orderIds);
        }
    }, [selectedOrders, openPrintable]);

    const [setPrintDialogOpen, printDialog] = usePrintDialog({
        title,
        onClick: renderPanLabels,
        autoClose: true,
    });

    return (
        <>
            <LabInboxBulkActionIconButton
                onClick={() => setPrintDialogOpen(true)}
                loading={false}
                disabled={disabled ?? false}
                disableTooltip={disabled ?? false}
                selectionCount={selectedOrders.length}
                tooltipTitle={title}
            >
                <Icon icon={'QrCode2'} />
            </LabInboxBulkActionIconButton>
            {printDialog}
            {printableIsOpen && (
                <PanLabelSeries
                    labOrderIds={selectedOrders.map(o => o.id)}
                    autoPrintEnabled={true}
                    onPrintComplete={closePrintable}
                />
            )}
        </>
    );
};
