import type { PartnerAppState } from '../redux';
import { AnalyticsClient } from './analyticsClient';
import { createReduxActionListenerMiddleware } from '@orthly/ui';
import type { Action } from 'redux-actions';
import { LOCATION_CHANGE } from 'redux-first-history';
import type { RouterState } from 'redux-first-history';

export const labPortalAnalyticsMiddleware = createReduxActionListenerMiddleware<PartnerAppState>([
    {
        config: {
            actions: [LOCATION_CHANGE],
        },
        handler: (_newState, action: Action<RouterState>, _oldState) => {
            if (action.payload.location) {
                AnalyticsClient.page(`LabPortal`, `Generic Page`, {
                    path: action.payload.location.pathname,
                });
            }
        },
    },
]);
