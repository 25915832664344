import { labPortalAnalyticsMiddleware } from './analytics/lab-portal-analytics.middleware';
import type { PartnerAppState } from './redux';
import { ordersReducer } from './redux/orders/orders.reducer';
import { uiReducer } from './redux/ui';
import { qcReduxReducer } from './screens/manufacturer/components/QcStation/state/QcStation.state';
import { manufacturerReducer } from './screens/manufacturer/state/manufacturer.reducer';
import { staffMemberReducer } from './screens/select-staff/state/select-staff.reducer';
import { createAsyncReducer } from '@orthly/redux-async-actions';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import type { AnyAction } from 'redux';
import { combineReducers } from 'redux';
import { applyMiddleware, compose, createStore } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import thunk from 'redux-thunk';

const { createReduxHistory, routerReducer, routerMiddleware } = createReduxHistoryContext({
    history: createBrowserHistory(),
});

const reducer = combineReducers<PartnerAppState>({
    async: createAsyncReducer(false),
    manufacturer: manufacturerReducer,
    orders: ordersReducer,
    router: routerReducer,
    ui: uiReducer,
    qcStation: qcReduxReducer,
    staffMember: staffMemberReducer,
});

const rootReducer = (state: PartnerAppState | undefined, action: AnyAction) => {
    return reducer(state, action);
};

const initRedux = () => {
    const initialState = {};
    const isDev = process.env.NODE_ENV === 'development' || process.env.REACT_APP_STAGING === 'true';
    const composeEnhancers =
        (isDev &&
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                trace: false,
                traceLimit: 5,
            })) ||
        compose;
    const middleware = [routerMiddleware, thunk];
    if (window.analytics) {
        middleware.unshift(labPortalAnalyticsMiddleware);
    }

    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(...middleware), Sentry.createReduxEnhancer()),
    );

    return store;
};

// we export singletons so that we use the same store across the entire app
export const store = initRedux();
export const reduxHistory = createReduxHistory(store);
