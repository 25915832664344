import { useStageSelections } from '../hooks/useStageSelection';
import { StyledForm, ScrollableStageList, StyledFormGroup, FormLabelText } from '../internal-remake.styles';
import type { InternalRemakeFormData, CompletedStage } from '../internal-remake.types';
import { validateInternalRemake, getSanitizedLabtracId } from '../internal-remake.utils';
import { StageSelector } from './StageSelector';
import { SimpleDateTimePicker, SimpleInput } from '@orthly/ui';
import { Button, Text } from '@orthly/ui-primitives';
import React from 'react';

interface InternalRemakeFormProps {
    completedStages: CompletedStage[];
    onSubmit: (data: InternalRemakeFormData) => void;
    isSubmitting: boolean;
}

export const InternalRemakeForm: React.VFC<InternalRemakeFormProps> = ({ completedStages, onSubmit, isSubmitting }) => {
    const [timestamp, setTimestamp] = React.useState<Date | null>(null);
    const [labtracId, setLabtracId] = React.useState<string>();
    const [reason, setReason] = React.useState<string>();
    const [validationError, setValidationError] = React.useState<string | null>(null);

    const { selections, toggleStageSelection, getSelectedStages } = useStageSelections(completedStages);

    const handleSubmit = () => {
        const error = validateInternalRemake(selections, labtracId);
        setValidationError(error);

        if (!error) {
            onSubmit({
                selectedStages: getSelectedStages(),
                newLabtracId: getSanitizedLabtracId(labtracId),
                reason,
                timestamp: timestamp ?? undefined,
            });
        }
    };

    return (
        <StyledForm>
            <ScrollableStageList>
                {completedStages.map(stage => (
                    <StageSelector
                        key={stage.id}
                        stageId={stage.id}
                        stageLabel={stage.label}
                        isChecked={selections[stage.id]?.selected ?? false}
                        onToggle={toggleStageSelection}
                    />
                ))}
            </ScrollableStageList>

            <StyledFormGroup>
                <FormLabelText>New Labtrac Case (STAGE 2)</FormLabelText>
                <SimpleInput fullWidth label={'CN-XXXXXX'} value={labtracId} onChange={setLabtracId} />
            </StyledFormGroup>
            <StyledFormGroup>
                <FormLabelText>Remake Reason Notes (Optional)</FormLabelText>
                <SimpleInput
                    fullWidth
                    label={'E.g. Chipped tooth in singulation..'}
                    value={reason}
                    onChange={setReason}
                />
            </StyledFormGroup>

            <StyledFormGroup>
                <FormLabelText>Set the IR timestamp to the past (Optional)</FormLabelText>
                <SimpleDateTimePicker
                    label={'Leave blank for current time'}
                    value={timestamp}
                    onChange={setTimestamp}
                />
            </StyledFormGroup>

            {validationError && (
                <StyledFormGroup>
                    <Text color={'REVIEW_RED'}>{validationError}</Text>
                </StyledFormGroup>
            )}

            <StyledFormGroup>
                <Button disabled={isSubmitting} onClick={handleSubmit} variant={'primary'}>
                    Record IR
                </Button>
            </StyledFormGroup>
        </StyledForm>
    );
};
