import { ActionCard, FlossPalette } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

const HOLDBACK_MESSAGE = 'Tomorrow';

function getHexEmbedLink(orderNumber: string): string {
    return `https://app.hex.tech/99fa47a2-e436-4bc7-9258-7cfb103b9003/app/b626832c-d55e-45a1-9b70-06e97f2d1752/latest?embedded=true&embeddedStaticCellId=8fe9a843-f4ba-4f40-a73d-de819ad66105&_scan_input=%22${orderNumber}%22`;
}

export const BatchInstructionsAndHex: React.VFC<{
    orderNumber: string;
    allOrdersReadyForBatching: boolean;
    shelfLabel: string;
    arrivedFrom: 'QC' | 'overseas';
    holdbackUntilTomorrow: boolean;
}> = props => {
    const { orderNumber, shelfLabel, allOrdersReadyForBatching, arrivedFrom, holdbackUntilTomorrow } = props;
    const [displayEmbed, setDisplayEmbed] = React.useState<boolean>(allOrdersReadyForBatching);
    const batchTodayMessage = allOrdersReadyForBatching ? 'Ready to batch and pack!' : 'Eligible for Batching';

    const holdbackActionCard = (
        <ActionCard
            variant={'announcement'}
            title={`${HOLDBACK_MESSAGE} [${shelfLabel}]`}
            subtitle={`Store on the holdback shelf; tomorrow it will move to shelf ${shelfLabel}`}
            style={{ minHeight: 'unset', padding: 8, margin: '0 0 8px', backgroundColor: FlossPalette.LIGHT_YELLOW }}
        />
    );

    const batchTodayActionCard = (
        <ActionCard
            variant={allOrdersReadyForBatching ? 'info' : 'alert'}
            title={`${batchTodayMessage} - ${shelfLabel}`}
            subtitle={
                allOrdersReadyForBatching
                    ? 'Click "Show Pans" to find packing list'
                    : `Store on ${shelfLabel} consolidation shelf if order just arrived from ${arrivedFrom}`
            }
            style={{ minHeight: 'unset', padding: 8, margin: '0 0 8px' }}
            primaryAction={{
                onClick: () => setDisplayEmbed(c => !c),
                buttonVariant: 'nav',
                text: displayEmbed ? 'Hide Pans' : 'Show Pans',
            }}
        />
    );

    return (
        <Grid container style={{ paddingBottom: 8 }}>
            {holdbackUntilTomorrow ? holdbackActionCard : batchTodayActionCard}
            {displayEmbed && (
                <Grid container>
                    <iframe src={getHexEmbedLink(orderNumber)} style={{ width: '100%', height: 300, border: 'none' }} />
                </Grid>
            )}
        </Grid>
    );
};
