import { SingleStageItem } from '../internal-remake.styles';
import { Checkbox, Text } from '@orthly/ui-primitives';
import React from 'react';

interface StageSelectorProps {
    stageId: string;
    stageLabel: string;
    isChecked: boolean;
    onToggle: (stageId: string) => void;
}

export const StageSelector: React.VFC<StageSelectorProps> = ({ stageId, stageLabel, isChecked, onToggle }) => (
    <SingleStageItem>
        <Checkbox checked={isChecked} onChange={() => onToggle(stageId)} aria-label={`Select ${stageLabel}`} />
        <Text>{stageLabel}</Text>
    </SingleStageItem>
);
